.dapp-topbar {
  width: 100%;
  align-items: center;
  a,
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 25px !important;
    padding: 25px !important;
    min-width: fit-content !important;
    min-height: 40px !important;
    width: 200px;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
}

.mobilelogo{
  visibility: hidden;
}

.tablet .dapp-topbar {
  justify-content: space-between;
}

.tablet .mobilelogo{
  visibility: visible;
}

.pending-txn-container {
  display: flex;
  align-items: center;
  padding: 10px !important;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
    font-size: 14px;
    font-weight: 500;
  }
}
#ohm-popper-pending {
  .MuiPaper-root.ohm-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}
